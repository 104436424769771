import React, { Component } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/PageTitle"
import autosize from "autosize"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class contact extends Component {
  constructor() {
    super()
    this.msgTextarea = React.createRef()
    this.contactForm = React.createRef()
    this.state = {}
  }
  componentDidMount() {
    autosize(this.msgTextarea.current)
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    const form = e.target
    fetch("/", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        alert("Successfully Submitted your message!")
        this.contactForm.current.reset()
      })
      .catch(error => alert(error))
    e.preventDefault()
  }
  render() {
    return (
      <Layout>
        <Seo title="Contact" />
        <PageTitle pageTitle="Contact Us" />
        <div className="post-background">
          <div className="post-container">
            <form
              onSubmit={this.handleSubmit}
              ref={this.contactForm}
              className="form-container"
              name="contact"
              method="post"
              data-netlify-honeypot="bot-field"
              data-netlify="true"
            >
              <h3>
                If you have any query or you want to hire or discuss any
                project.
              </h3>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="input-group">
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="name" className="label-name">
                  <span className="content-name">Name</span>
                </label>
              </div>
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="email" className="label-name">
                  <span className="content-name">Email</span>
                </label>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  name="subject"
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="subject" className="label-name">
                  <span className="content-name">Subject</span>
                </label>
              </div>
              <div className="textarea-group">
                <textarea
                  type="text"
                  ref={this.msgTextarea}
                  name="message"
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="message" className="label-text">
                  <span className="textarea-name">Message</span>
                </label>
              </div>

              <div className="input-submit">
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}
